import { policyList } from "@/enums/permissions";
import { MetrcItem } from "@/interfaces/itemManager";
import { metrcItemService } from "@/services/metrcItemManager.service";
import { PageNavAction } from "@/types/types";
import {
  BooleanCheck,
  TableAction,
  TableComponent,
  TableFieldType,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./itemManager.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
export default class ItemManagerComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  public metrcItems: MetrcItem[] = [];
  public pagination: TablePagination = { ...TablePaginationDefault };
  public enableMultiActions = false;

  public loading = false;
  public search: string = "";
  public tableHeaders: TableHeader[] = [];
  public tableColors: string[] = [
    "#ffffff",
    "#f2f2f2",
    "#f2f2f2",
    "#ffffff",
    "#ffffff"
  ];
  public rowActions: TableAction[] = [];
  public multiActions: TableAction[] = [];

  public editItem(item: MetrcItem) {
    this.$router.push({
      name: "item-manager-edit",
      params: {
        id: String(item.id)
      }
    });
  }

  public async mounted() {
    this.setPageNav({
      title: this.$t("metrc.metrc_item_mgm").toString(),
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-search",
            otherComponent: {
              name: "TableSearchComponent",
              action: search => {
                if (!search) {
                  this.pagination.currentPage = 1;
                  this.search = "";
                } else {
                  this.search = search;
                }
                this.getItems();
              },
              config: {
                labelText: "search_by_name"
              }
            }
          },
          {
            icon: "fal fa-plus",
            visibleCondition: () =>
              this.hasPermission(policyList.createMetrcItems),
            action: () => {
              this.$router.push({
                name: "item-manager-add"
              });
            }
          }
        ]
      }
    });

    this.tableHeaders = [
      {
        label: this.$t("name").toString(),
        value: "name",
        sortable: false,
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong"
      }
    ];

    this.rowActions = [
      {
        icon: "fal fa-pen",
        action: this.editItem,
        visibleCondition: () => this.hasPermission(policyList.modifyMetrcItems)
      },
      {
        icon: "fal fa-trash",
        visibleCondition: () => this.hasPermission(policyList.deleteMetrcItems),
        modalActions: {
          modalNumber: 1,
          modalQuestion: String(this.$t("metrc.item_toggle_confirm")),
          modalSuccessText: "yes",
          modalSuccessAction: ({ item, unselectModal }) => {
            metrcItemService
              .deleteItem(item)
              .then(() => unselectModal())
              .finally(() => {
                if (this.pagination.from === this.pagination.to) {
                  this.pagination.currentPage -= 1;
                }
                this.getItems();
              });
          },
          modalCancelText: "No"
        }
      }
    ];

    this.enableMultiActions = this.hasPermission(policyList.deleteMetrcItems);

    this.multiActions = [
      {
        icon: "fal fa-trash",
        action: this.deleteMultiple
      }
    ];

    this.getItems();
  }

  public async deleteMultiple(items: MetrcItem[]) {
    const ids = items.map(item => item.id);
    this.loading = true;
    this.pagination.currentPage = 1;
    await metrcItemService.deleteMultiple(ids);
    this.getItems();
  }

  public onPaginate(pagination: TablePagination) {
    this.pagination = pagination;
    this.getItems();
  }

  protected async getItems() {
    this.loading = true;
    const query: {
      per_page: number;
      page: number;
      name?: string;
    } = {
      per_page: this.pagination.itemsPerPage,
      page: this.pagination.currentPage,
      name: this.search
    };
    if (!this.search) {
      delete query.name;
    }
    this.metrcItems = await metrcItemService.getItems(query);
    this.pagination = await metrcItemService.getPagination();
    this.loading = false;
  }
}

import { MetrcItem, MetrcItemModel, MetrcUnit } from "@/interfaces/itemManager";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class MetrcItemService extends HttpService {
  public async getItems(
    query: { [key: string]: string | number | undefined } | null = null
  ) {
    try {
      this.uri = "/traceability/metrc/items";
      const response = await super.get(query);
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return [];
    }
  }

  public async deleteItem(item: MetrcItem) {
    try {
      this.uri = "/traceability/metrc/items";
      const response = await super.delete(item);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getCategories(query = {}) {
    try {
      const response = await this.axios.get("/traceability/metrc/categories", {
        params: query
      });
      return response.data.data.data;
    } catch (error) {
      return [];
    }
  }

  public async getStrains(query: { [key: string]: string } | null = null) {
    try {
      const response = await this.axios.get("/traceability/metrc/strains", {
        params: query
      });
      return response.data.data.data;
    } catch (error) {
      return [];
    }
  }
  public async getUOM(): Promise<MetrcUnit[]> {
    try {
      const response = await this.axios.get(
        "/traceability/metrc/units_of_measure"
      );
      return response.data.data.data;
    } catch (error) {
      return [];
    }
  }

  public async createItem(item: MetrcItemModel) {
    try {
      this.uri = "/traceability/metrc/items";
      const response = await super.post(item);
      return response;
    } catch (error) {
      return { errors: messagesService.parseMetrcError(error) };
    }
  }

  public async updateItem(item: MetrcItemModel) {
    try {
      this.uri = "/traceability/metrc/items";
      const response = await super.put(item, item);
      return response;
    } catch (error) {
      return { errors: messagesService.parseMetrcError(error) };
    }
  }

  public async findItem(id: string) {
    try {
      this.uri = "/traceability/metrc/items";
      const response = await super.findById(id);
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async deleteMultiple(ids: number[]) {
    try {
      const response = await this.axios.delete("/traceability/metrc/items", {
        data: {
          ids
        }
      });
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }
}

export const metrcItemService = new MetrcItemService();
